export default {
  message: {
    hello: "你好",
  },
  label:{
    addOrder:"添加订单",
    addType:"添加分类",
    addTeacher:"添加教师",
    addCourse:"添加课程",
    addBanner:"添加轮播图",
    addVipPrice:"添加会员价格",
    addActiveCode:"添加卡密"
  },
  nice: "好",

  leftside:{
    user_center:'用户中心'
  }
};
